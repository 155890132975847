<div *ngIf="isDivVisible" class="deletemodalbg">
  <div class="deletemodal">
    <i class="far fa-times-circle dltcross" (click)="cancel()"></i>
    <p style="font: normal normal 400 1.5vw Roboto;color: #0051AC;text-align: center;margin-bottom: 2vw;">
      {{clientsPage?.AreYouSure}}
    </p>
    <!-- <p style="font: normal normal 400 1vw Roboto;text-align: center;color: #49586C;margin-bottom: 2vw;">
      {{clientsPage?.DoYouReallyWant}}</p> -->
    <div class="polyBtnDiv d-flex align-items-center justify-content-center gap-5 btn-div">
      <button class="save-btn" (click)="deleteUser(deleteLocationData);cancel()">
        <i class="fas fa-trash-alt"></i>
        {{clientsPage?.Delete}}
      </button>
      <button class="cancel-btn" (click)="cancel()">
        <i class="fas fa-times"></i>
        {{clientsPage?.Cancel}}
      </button>
    </div>
  </div>
</div>

<!-- <div class="page-titlealert">
    <i class="fas fa-caret-right"></i>
    <p class="active">{{clientsPage?.Clients}}</p>
  </div> -->
<!-- <div class="locationBG justify-content-between">
    <div class="addLocHeading">
      <img class="headerIcon" src="../../../assets/addusers.svg" />
      <h3>{{clientsPage?.AddNewClient}}</h3>
    </div>
    <div class="d-flex align-items-center">
      <button pButton type="button" (click)="openFormWindow()" class="bgBtn addNewUserBtn" [label]="clientsPage?.AddNewClient"><img
          src="../../../assets/addIcon.svg" /></button>
    </div>
  </div> -->

<p-toolbar class="row" style="margin: inherit;">
  <div class="p-toolbar-group-left col justify-content-between">
    <div class="d-flex gap-2 align-items-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="20" viewBox="0 0 17.5 20">
        <path id="Icon_fa-solid-user-tie" data-name="Icon fa-solid-user-tie" d="M8.75,10a5,5,0,1,1,5-5A5,5,0,0,1,8.75,10Zm-.582,4.031L7.441,12.82a.624.624,0,0,1,.535-.945H9.52a.625.625,0,0,1,.535.945l-.727,1.211,1.3,4.84,1.406-5.738a.578.578,0,0,1,.7-.441A6.3,6.3,0,0,1,17.5,18.8,1.2,1.2,0,0,1,16.3,20H11.152a.615.615,0,0,1-.227-.043l.012.043H6.563l.012-.043A.631.631,0,0,1,6.348,20H1.2A1.2,1.2,0,0,1,0,18.8a6.3,6.3,0,0,1,4.762-6.109.584.584,0,0,1,.7.441l1.406,5.738,1.3-4.84Z" fill="#005bb5"/>
      </svg>
      <div class="alert-history">{{clientsPage?.Clients}}
      </div>
    </div>
    <div class="d-flex gap-3">
      <button *ngxPermissionsOnly="['invite_user']" (click)="openTempWindow()" class="header-btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <path id="Icon_fa-solid-envelope-open-text" data-name="Icon fa-solid-envelope-open-text" d="M8.414,3.75H3.75v6.93L.008,7.91a2.5,2.5,0,0,1,1-1.789l.863-.641V3.75A1.875,1.875,0,0,1,3.75,1.875H6.742L8.691.434A2.192,2.192,0,0,1,10,0a2.215,2.215,0,0,1,1.309.43l1.949,1.445H16.25A1.875,1.875,0,0,1,18.125,3.75V5.48l.863.641a2.5,2.5,0,0,1,1,1.789L16.25,10.68V3.75ZM0,17.5V9.457l8.5,6.3a2.531,2.531,0,0,0,1.5.5,2.5,2.5,0,0,0,1.5-.5l8.5-6.3V17.5A2.5,2.5,0,0,1,17.5,20H2.5A2.5,2.5,0,0,1,0,17.5ZM6.875,6.25h6.25a.625.625,0,0,1,0,1.25H6.875a.625.625,0,0,1,0-1.25Zm0,2.5h6.25a.625.625,0,0,1,0,1.25H6.875a.625.625,0,0,1,0-1.25Z" class="svg"/>
        </svg>
        {{clientsPage?.AddTempClient || 'Add Temp Client'}}
      </button>
      <button *ngxPermissionsOnly="['invite_user']" (click)="openBulkFormWindow()" class="bulk-btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <g id="Icon_ion-create" data-name="Icon ion-create" transform="translate(-1 -1)">
            <path id="Path_65465" data-name="Path 65465" d="M28.94,3.673a.912.912,0,0,0-1.319-.032l-.7.7a.455.455,0,0,0,0,.643l.644.643a.455.455,0,0,0,.644,0l.685-.682A.924.924,0,0,0,28.94,3.673ZM25.5,5.761,15.24,16a.511.511,0,0,0-.131.223l-.474,1.413a.222.222,0,0,0,.276.276l1.412-.474a.511.511,0,0,0,.223-.131L26.784,7.049a.511.511,0,0,0,0-.719l-.565-.568A.511.511,0,0,0,25.5,5.761Z" transform="translate(-10.09 -0.456)" class="svg"/>
            <path id="Path_65466" data-name="Path 65466" d="M22.6,12.515l-6.926,6.939a2.334,2.334,0,0,1-.959.58l-1.472.493a2.041,2.041,0,0,1-2.519-2.519l.493-1.472a2.334,2.334,0,0,1,.579-.959l6.939-6.927a.455.455,0,0,0-.321-.776H6.557a3.182,3.182,0,0,0-3.182,3.182V24.693a3.182,3.182,0,0,0,3.182,3.182H20.193a3.182,3.182,0,0,0,3.182-3.182V12.836a.455.455,0,0,0-.776-.321Z" transform="translate(-2.375 -6.875)" class="svg"/>
          </g>
        </svg>
        {{clientsPage?.AddBulkClients}}
      </button>
      <button *ngxPermissionsOnly="['invite_user']" (click)="openManuallyFormWindow()" class="header-btn"> <!--create_manual_user-->
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <g id="Icon_ion-create" data-name="Icon ion-create" transform="translate(-1 -1)">
            <path id="Path_65465" data-name="Path 65465" d="M28.94,3.673a.912.912,0,0,0-1.319-.032l-.7.7a.455.455,0,0,0,0,.643l.644.643a.455.455,0,0,0,.644,0l.685-.682A.924.924,0,0,0,28.94,3.673ZM25.5,5.761,15.24,16a.511.511,0,0,0-.131.223l-.474,1.413a.222.222,0,0,0,.276.276l1.412-.474a.511.511,0,0,0,.223-.131L26.784,7.049a.511.511,0,0,0,0-.719l-.565-.568A.511.511,0,0,0,25.5,5.761Z" transform="translate(-10.09 -0.456)" class="svg"/>
            <path id="Path_65466" data-name="Path 65466" d="M22.6,12.515l-6.926,6.939a2.334,2.334,0,0,1-.959.58l-1.472.493a2.041,2.041,0,0,1-2.519-2.519l.493-1.472a2.334,2.334,0,0,1,.579-.959l6.939-6.927a.455.455,0,0,0-.321-.776H6.557a3.182,3.182,0,0,0-3.182,3.182V24.693a3.182,3.182,0,0,0,3.182,3.182H20.193a3.182,3.182,0,0,0,3.182-3.182V12.836a.455.455,0,0,0-.776-.321Z" transform="translate(-2.375 -6.875)" class="svg"/>
          </g>
        </svg>
        {{clientsPage?.CreateUserManually}}
      </button>
      <button *ngxPermissionsOnly="['invite_user']" (click)="openFormWindow()" class="header-btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <path id="Icon_fa-solid-envelope-open-text" data-name="Icon fa-solid-envelope-open-text" d="M8.414,3.75H3.75v6.93L.008,7.91a2.5,2.5,0,0,1,1-1.789l.863-.641V3.75A1.875,1.875,0,0,1,3.75,1.875H6.742L8.691.434A2.192,2.192,0,0,1,10,0a2.215,2.215,0,0,1,1.309.43l1.949,1.445H16.25A1.875,1.875,0,0,1,18.125,3.75V5.48l.863.641a2.5,2.5,0,0,1,1,1.789L16.25,10.68V3.75ZM0,17.5V9.457l8.5,6.3a2.531,2.531,0,0,0,1.5.5,2.5,2.5,0,0,0,1.5-.5l8.5-6.3V17.5A2.5,2.5,0,0,1,17.5,20H2.5A2.5,2.5,0,0,1,0,17.5ZM6.875,6.25h6.25a.625.625,0,0,1,0,1.25H6.875a.625.625,0,0,1,0-1.25Zm0,2.5h6.25a.625.625,0,0,1,0,1.25H6.875a.625.625,0,0,1,0-1.25Z" class="svg"/>
        </svg>
        {{clientsPage?.AddNewClient}}
      </button>
    </div>
  </div>
</p-toolbar>

<div class="AddUserForm" *ngIf="AddBulk">
  <div class="d-flex py-3">
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio1" [checked]="!isManualBulkClient" [value]="!isExcelBulkClient" (click)="onExcelBulkClick($event)">
      <label [ngClass]="language === 'en' ? 'ps-3' : 'pe-3'" class="form-check-label" style="font-size: 16px; color: #005bb5;" for="inlineRadio1">{{ clientsPage?.AddBulkClientsByExcel }}</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio2" [checked]="!isExcelBulkClient" [value]="!isManualBulkClient" (click)="onManualBulkClick($event)">
      <label [ngClass]="language === 'en' ? 'ps-3' : 'pe-3'" class="form-check-label" style="font-size: 16px; color: #005bb5;" for="inlineRadio2">{{ clientsPage?.AddBulkClientsManually }}</label>
    </div>
  </div>
  <div *ngIf="isExcelBulkClient">
    <div [ngClass]="{'disable-form-container': progressStarted}">
      <div class="d-flex flex-wrap flex-column col-12">
        <h6 class="invitemembersfieldtext">{{clientsPage?.SelectAnOrganization}}</h6>
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="client-type-select  mb-2 col-xl-2 col-lg-4 col-md-6 col-12"
          style="width: 12rem !important;">
          <mat-label>{{clientsPage?.organization}}</mat-label>
          <mat-select msInfiniteScroll (infiniteScroll)="infiniteBulkOrganizationUsersForDropdown()" floatLabel="always"
            [formControl]="organization_id">
            <input matInput [placeholder]="clientsPage?.Search" [formControl]="bulkImportControl"
              class="packageinputfilter">
            <mat-option *ngIf="!isLoadingBulkOrganization" class="d-none"></mat-option>
            <mat-option *ngIf="isLoadingBulkOrganization">
              <div class="d-flex justify-content-center">
                <i class="fa fa-spinner spinner--icon-select"></i>
              </div>
            </mat-option>
            <mat-option [value]="organization?.id" *ngFor="let organization of bulkOrganizationUsers?.data">
              {{language === 'en' ? organization?.nameEn : organization?.nameAr}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <input (change)="handleFile($event, file)" #file type="file" class="d-none"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">

      <div class="d-flex align-items-center gap-3 my-3 ">
        <button [disabled]="progressStarted" [ngClass]="{'disabled': progressStarted}" (click)="file.click()"
          class="header-btn">
          <i class="far fa-file-excel excel"></i> &nbsp;
          {{clientsPage?.BrowseFile}}
        </button>
        <span class="file-name">{{excelFile?.name}}</span>
      </div>

      <div class="d-flex align-items-center gap-2">
        <a target="_blank"
          href="https://ncmportal.centric.ae/Bulk-Client-Format.xlsx">{{clientsPage?.DownloadExampleFormat}}</a>
      </div>

      <!-- <div class="d-flex align-items-center gap-2 my-4">
        <input [ngClass]="{'disabled': progressStarted}" class="checkbox" #check [formControl]="severeWeatherAlert" type="checkbox"> <label (click)="check.click()">{{locationPage?.SevereWeatherAlert}}</label>
      </div> -->
    </div>

    <div *ngIf="progressStarted" class="progress-container">
      <div class="progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
          aria-valuemin="0" aria-valuemax="100" [style.width]="calculateProgress()">{{calculateProgress()}}</div>
      </div>
    </div>
    <div *ngIf="!progressStarted" class="d-flex justify-content-start align-items-center gap20 mt-5">
      <button [ngClass]="{'disabled-btn':!excelFile?.name || !organization_id.value}" [disabled]="!excelFile?.name || !organization_id?.value"
      class="send-invitation-btn" (click)="onExcelSubmit()">
        <ng-container *ngIf="!isLoading else loader">
          {{clientsPage?.SendInvitations}}
        </ng-container>
      </button>
      <button [ngClass]="{'disable-close-btn': progressStarted}" (click)="closeExcelFormWindow()" class="cancel-btn">
        <i class="fas fa-times"></i>
        {{clientsPage?.Cancel}}
      </button>
    </div>
    <ng-template #loader>
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </ng-template>
  </div>
  <div *ngIf="isManualBulkClient">
    <form [formGroup]="bulkForm">
      <div class="d-flex flex-wrap gap-3">
        <div class="d-flex flex-column">
          <ng-container>
            <h6 class="invitemembersfieldtext">{{clientsPage?.SelectAnOrganization}}</h6>
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline"
              style="width: 12rem !important;"
              class="sameWidth client-lin-marg-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
              <mat-label>{{clientsPage?.organization}}</mat-label>
              <mat-select msInfiniteScroll (infiniteScroll)="infiniteOrganizationUsersForDropdown()"
                formControlName="organization_id">
                <input matInput [placeholder]="clientsPage?.Search" [formControl]="addBulkImportManually" class="packageinputfilter">
                <mat-option *ngIf="isLoadingOrganization">
                  <div class="d-flex justify-content-center">
                    <i class="fa fa-spinner spinner--icon-select"></i>
                  </div>
                </mat-option>
                <mat-option [value]="organization?.id" *ngFor="let organization of organizationUsers?.data">
                  {{language === 'en' ? organization?.nameEn : organization?.nameAr}}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
      <div class="d-none justify-content-start align-items-center gap20 my-30">
        <button pButton type="button" class="bgBtn paddingBtn" (click)="fileInput.click()"
          [label]="clientsPage?.Chooselogo"></button>
        <input type='file' (change)="onFileChanged($event)" style="display:none" #fileInput>
      </div>
      <div class="brdr-bottom-grey py-4">
        <h5 class="invitememberstext m-0">{{clientsPage?.InviteMembers}}</h5>
      </div>
      <div class="container-fluid pt-5">
        <div class="row overflow-auto flex-nowrap gap-3">
          <!-- <form > -->
          <ng-container *ngFor="item of bulkForm.controls['count']?.['controls']; index as j">
            <div *ngIf="j % 5 === 0"
              class="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12 m-0 p-0 custombrdr position-relative"
              style="width: auto;" formArrayName="count">
              <div class="form-row py-3" [ngClass]="language == 'en' ? 'pe-3' : 'ps-3'">
                <div [id]="j" class="d-flex align-items-center gap-3 pb-3"
                  *ngFor="let item of bulkForm.controls['count']?.['controls'].slice(j, j + 5); index as i" [formGroupName]="j+i">
                  <div class="d-flex flex-column">
                    <h6 class="invitemembersfieldtext">{{clientsPage?.Name}}</h6>
                    <!-- <input type="text" formControlName="name" class="inputtext sameWidth" placeholder="Name"> -->
                    <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameWidth" appearance="outline">
                      <mat-label>{{clientsPage?.Name}}</mat-label>
                      <input formControlName="name" type="text" matInput>
                    </mat-form-field>
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="invitemembersfieldtext">{{clientsPage?.Emails}}</h6>
                    <!-- <input type="email" formControlName="email" class="inputtext sameWidth" placeholder="Email"> -->
                    <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameWidth" appearance="outline">
                      <mat-label>{{clientsPage?.Emails}}</mat-label>
                      <input formControlName="email" type="text" matInput>
                    </mat-form-field>
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="invitemembersfieldtext">{{clientsPage?.Permissions}}</h6>
                    <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
                      style="width: 10rem !important; margin: 0 !important;" appearance="outline"
                      class="sameWidth client-lin-marg-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
                      <mat-label>{{clientsPage?.PermissionType}}</mat-label>
                      <mat-select formControlName="role" floatLabel="always">
                        <mat-option value="CLIENT_ADMIN">{{clientsPage?.Admin}}</mat-option>
                        <mat-option value="CLIENT_USER">{{clientsPage?.User}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- <ng-select placeholder="" formControlName="" [searchable]="false">
                        <ng-option value="admin">Admin</ng-option>
                        <ng-option value="client">Client</ng-option>
                      </ng-select> -->
                  </div>
                  <div class="removefieldclass" (click)="removeCount(j)"
                    [ngClass]="{'disabled': count().controls.length === 1}">
                    <img src="../../../assets/removefield-icon.png">
                  </div>
                </div>

              </div>
            </div>
          </ng-container>
          <!-- </form> -->
        </div>
        <div container="body" placement="top"
          [ngbTooltip]="count().controls.length == 10 ? 'Cannot add more then 10 fields' : ''"
          class="addanotherfieldclass pt-3" (click)="addcount()"
          [ngClass]="{'disableds': count().controls.length === 10}">
          <img src="../../../assets/addanotherfield-icon.png">
          <h6 class="addanotherusertextclass m-0">{{clientsPage?.AddAnotherUser}}</h6>
        </div>
      </div>
      <div class="d-flex justify-content-start align-items-center gap20 mt-5">
        <button class="send-invitation-btn" (click)="onSubmit()">
          {{clientsPage?.SendInvitations}}
        </button>
        <button (click)="closeBulkFormWindow()" class="cancel-btn">
          <i class="fas fa-times"></i>
          {{clientsPage?.Cancel}}
        </button>
      </div>
    </form>
  </div>
</div>
<div class="AddUserForm" *ngIf="AddManualUser">
  <form [formGroup]="manualClientForm">
    <div class="d-flex flex-wrap gap-3">
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
        <mat-label>{{clientsPage?.ClientType}}</mat-label>
        <mat-select formControlName="client_type" [placeholder]="clientsPage?.SelectType" floatLabel="always" (valueChange)="roleSelect('manualClientForm', $event)">
          <mat-option value="government">{{clientsPage?.Government}}</mat-option>
          <mat-option value="organization">{{clientsPage?.Organization}}</mat-option>
          <mat-option *ngIf="!manualClientForm.controls['id'].value" value="user">{{clientsPage?.user}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <input type="text" formControlName="name" class="inputtext sameWidth mb-2 col-xl-2 col-lg-4 col-md-6 col-12" [placeholder]="clientsPage?.Name"> -->
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
        <mat-label>{{clientsPage?.Name}}</mat-label>
        <input formControlName="name" type="text" matInput>
      </mat-form-field>

      <!-- <input type="text" formControlName="company_name_en" class="inputtext sameWidth mb-2 col-xl-2 col-lg-4 col-md-6 col-12"
          [placeholder]="clientsPage?.OrganizationNameEN"> -->
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
        <mat-label>{{clientsPage?.OrganizationNameEN}}</mat-label>
        <input formControlName="company_name_en" type="text" matInput>
      </mat-form-field>

      <!-- <input type="text" formControlName="company_name_ar" class="inputtext sameWidth mb-2 col-xl-2 col-lg-4 col-md-6 col-12"
          [placeholder]="clientsPage?.OrganizationNameAR"> -->
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
        <mat-label>{{clientsPage?.OrganizationNameAR}}</mat-label>
        <input formControlName="company_name_ar" type="text" matInput>
      </mat-form-field>

      <!-- <input type="email" formControlName="email" class="inputtext sameWidth mb-2 col-xl-2 col-lg-4 col-md-6 col-12" [placeholder]="clientsPage?.AddEmail"> -->
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
        <mat-label>{{clientsPage?.AddEmail}}</mat-label>
        <input formControlName="email" type="text" matInput>
      </mat-form-field>

      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
        <mat-label>{{clientsPage?.Phone}}</mat-label>
        <input formControlName="phone" type="tel" matInput (keypress)="onPhoneInput($event)">
      </mat-form-field>

      <ng-container *ngIf="isPackageEdit">
        <ng-container *ngIf="manualClientForm.controls['client_type']?.value != 'user'">
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
            <mat-label>{{clientsPage?.Packages}}</mat-label>
            <mat-select msInfiniteScroll (infiniteScroll)="infinitePackagesList()" formControlName="package_id">
              <input matInput [placeholder]="clientsPage?.Search" (keyup)="searchPckg($event)" [formControl]="pkgSearchControl" class="packageinputfilter">
              <mat-option *ngIf="isLoadingPackagesList">
                <div class="d-flex justify-content-center">
                  <i class="fa fa-spinner spinner--icon-select"></i>
                </div>
              </mat-option>
              <mat-option [value]="package?.id" *ngFor="let package of listPackages?.data">
                {{language === 'en' ? package?.name : package?.name_ar}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="manualClientForm.controls['client_type']?.value == 'user'">
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline"
          class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
          <mat-label>{{clientsPage?.organization}}</mat-label>
          <mat-select msInfiniteScroll (infiniteScroll)="infiniteOrganizationUsersForDropdown()"
            formControlName="organization_id">
            <input matInput [placeholder]="clientsPage?.Search" [formControl]="addManualUserControl" class="packageinputfilter">
            <mat-option *ngIf="isLoadingOrganization">
              <div class="d-flex justify-content-center">
                <i class="fa fa-spinner spinner--icon-select"></i>
              </div>
            </mat-option>
            <mat-option [value]="organization?.id" *ngFor="let organization of organizationUsers?.data">
              {{language === 'en' ? organization?.nameEn : organization?.nameAr}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <div class="col-xl-2 col-lg-4 col-md-6 col-12 d-flex flex-column">
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
          class="sameHeight" appearance="outline">
          <mat-label>{{clientsPage?.Password}}</mat-label>
          <input formControlName="password" (keyup)="checkPasswordStrength()" [type]="PASSWORD_VISIBILITY.password ? 'text' : 'password'" matInput appInputPasswordPlaceholder [setPasswordPlaceholder]="this.manualClientForm?.controls?.['id']?.value" [autocomplete]="'new-password'" class="no--eye-input">
          <mat-icon class="password--suffix-icon" matSuffix *ngIf="!PASSWORD_VISIBILITY.password && manualClientForm.controls['password']?.value && manualClientForm.controls['password']?.value !== PASSWORD_PLACEHOLDER" (click)="PASSWORD_VISIBILITY.password = true">visibility</mat-icon>
          <mat-icon class="password--suffix-icon" matSuffix *ngIf="PASSWORD_VISIBILITY.password && manualClientForm.controls['password']?.value && manualClientForm.controls['password']?.value !== PASSWORD_PLACEHOLDER" (click)="PASSWORD_VISIBILITY.password = false">visibility_off</mat-icon>
        </mat-form-field>
        <div class="password-strength">
          <small *ngIf="passwordStrengthMessage" [ngClass]="passwordStrengthClass">
            {{clientsPage?.PasswordStrength}} {{ passwordStrengthMessage }}
          </small>
        </div>
        <div class="error" *ngIf="submitted && manualClientForm.controls['password'].errors?.['required']">
          {{clientsPage?.PasswordIsRequired}}
        </div>
      </div>

      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
        <mat-label>{{clientsPage?.ConfirmPassword}}</mat-label>
        <input formControlName="confirmPassword" [type]="PASSWORD_VISIBILITY.confirmPassword ? 'text' : 'password'" matInput appInputPasswordPlaceholder  [setPasswordPlaceholder]="this.manualClientForm?.controls?.['id']?.value" [autocomplete]="'new-password-confirm'" class="no--eye-input">
        <mat-icon class="password--suffix-icon" matSuffix *ngIf="!PASSWORD_VISIBILITY.confirmPassword && manualClientForm.controls['confirmPassword']?.value && manualClientForm.controls['confirmPassword']?.value !== PASSWORD_PLACEHOLDER" (click)="PASSWORD_VISIBILITY.confirmPassword = true">visibility</mat-icon>
        <mat-icon class="password--suffix-icon" matSuffix *ngIf="PASSWORD_VISIBILITY.confirmPassword && manualClientForm.controls['confirmPassword']?.value && manualClientForm.controls['confirmPassword']?.value !== PASSWORD_PLACEHOLDER" (click)="PASSWORD_VISIBILITY.confirmPassword = false">visibility_off</mat-icon>
      </mat-form-field>

      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
        <mat-label>{{clientsPage?.PermissionType}}</mat-label>
        <mat-select formControlName="role" floatLabel="always">
          <mat-option value="CLIENT_ADMIN">{{clientsPage?.Admin}}</mat-option>
          <mat-option value="CLIENT_USER">{{clientsPage?.User}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="manualClientForm?.controls['client_type'].value == 'organization'" appearance="outline"
        [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
        <mat-label>{{clientsPage?.EntityStatus || 'Entity Status'}}</mat-label>
        <mat-select formControlName="entity_status" floatLabel="always">
          <mat-option value="trial">{{clientsPage?.trial || 'trial'}}</mat-option>
          <mat-option value="paid">{{clientsPage?.paid || 'paid'}}</mat-option>
          <mat-option *ngIf="isEditClient" value="expired">{{clientsPage?.Expired || 'Expired'}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="manualClientForm?.controls['entity_status'].value == 'trial'" appearance="outline"
        [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
        <mat-label>{{clientsPage?.TrialLimit || 'Trial Limit'}}</mat-label>
        <mat-select formControlName="trialLimit" floatLabel="always">
          <mat-option *ngFor="let i of DaysArray; index as j" [value]="i">
            {{ i }} {{j === 0 ? clientsPage?.Day : clientsPage?.Days}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-none justify-content-start align-items-center gap20 my-30">
      <button pButton type="button" class="bgBtn paddingBtn" (click)="fileInput.click()"
        [label]="clientsPage?.Chooselogo"></button>
      <input type='file' (change)="onFileChanged($event)" style="display:none" #fileInput>
    </div>
    <div class="d-flex justify-content-start align-items-center gap20 mt-5">
      <button (click)="onCreateManualUser()" class="save-btn">
        <i class="far fa-save"></i>
        {{clientsPage?.SaveClient}}
      </button>
      <button (click)="closeManuallyFormWindow()" class="cancel-btn">
        <i class="fas fa-times"></i>
        {{clientsPage?.Cancel}}
      </button>
    </div>
  </form>
</div>
<div class="AddUserForm" *ngIf="AddClient">
  <form [formGroup]="clientForm">
    <div class="d-flex flex-wrap gap-3">
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
        <mat-label>{{clientsPage?.ClientType}}</mat-label>
        <mat-select formControlName="client_type" [placeholder]="clientsPage?.SelectType" floatLabel="always" (valueChange)="roleSelect('clientForm', $event)">
          <mat-option value="government">{{clientsPage?.Government}}</mat-option>
          <mat-option value="organization">{{clientsPage?.Organization}}</mat-option>
          <mat-option *ngIf="!clientForm.controls['id'].value" value="user">{{clientsPage?.user}}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <input type="text" formControlName="name" class="inputtext sameWidth mb-2 col-xl-2 col-lg-4 col-md-6 col-12" [placeholder]="clientsPage?.Name"> -->
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
        <mat-label>{{clientsPage?.Name}}</mat-label>
        <input formControlName="name" type="text" matInput>
      </mat-form-field>

      <!-- <input type="text" formControlName="company_name_en" class="inputtext sameWidth mb-2 col-xl-2 col-lg-4 col-md-6 col-12"
          [placeholder]="clientsPage?.OrganizationNameEN"> -->
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
        <mat-label>{{clientsPage?.OrganizationNameEN}}</mat-label>
        <input formControlName="company_name_en" type="text" matInput>
      </mat-form-field>

      <!-- <input type="text" formControlName="company_name_ar" class="inputtext sameWidth mb-2 col-xl-2 col-lg-4 col-md-6 col-12"
          [placeholder]="clientsPage?.OrganizationNameAR"> -->
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
        <mat-label>{{clientsPage?.OrganizationNameAR}}</mat-label>
        <input formControlName="company_name_ar" type="text" matInput>
      </mat-form-field>

      <!-- <input type="email" formControlName="email" class="inputtext sameWidth mb-2 col-xl-2 col-lg-4 col-md-6 col-12" [placeholder]="clientsPage?.AddEmail"> -->
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
        <mat-label>{{clientsPage?.AddEmail}}</mat-label>
        <input formControlName="email" type="text" matInput>
      </mat-form-field>

      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
        <mat-label>{{clientsPage?.Phone}}</mat-label>
        <input formControlName="phone" type="tel" matInput (keypress)="onPhoneInput($event)">
      </mat-form-field>

      <ng-container *ngIf="isPackageEdit">
        <ng-container *ngIf="clientForm.controls['client_type']?.value != 'user'">
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline"
            class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
            <mat-label>{{clientsPage?.Packages}}</mat-label>
            <mat-select msInfiniteScroll (infiniteScroll)="infinitePackagesList()" formControlName="package_id">
              <input matInput [placeholder]="clientsPage?.Search" (keyup)="searchPckg($event);" class="packageinputfilter">
              <mat-option *ngIf="isLoadingPackagesList">
                <div class="d-flex justify-content-center">
                  <i class="fa fa-spinner spinner--icon-select"></i>
                </div>
              </mat-option>
              <mat-option [value]="package?.id" *ngFor="let package of listPackages?.data">{{language === 'en' ?
                package?.name: package?.name_ar}}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="clientForm.controls['client_type']?.value == 'user'">
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline"
          class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
          <mat-label>{{clientsPage?.organization}}</mat-label>
          <mat-select msInfiniteScroll (infiniteScroll)="infiniteOrganizationUsersForDropdown()" formControlName="organization_id">
            <input matInput formControlName="filterorganizationname" [placeholder]="clientsPage?.Search" [formControl]="entityInviteClientControl" class="packageinputfilter">
            <mat-option *ngIf="isLoadingOrganization">
              <div class="d-flex justify-content-center">
                <i class="fa fa-spinner spinner--icon-select"></i>
              </div>
            </mat-option>
            <mat-option [value]="organization?.id" *ngFor="let organization of organizationUsers?.data">
              {{language === 'en' ? organization?.nameEn : organization?.nameAr}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
        <mat-label>{{clientsPage?.PermissionType}}</mat-label>
        <mat-select formControlName="role" floatLabel="always">
          <mat-option value="CLIENT_ADMIN">{{clientsPage?.Admin}}</mat-option>
          <mat-option value="CLIENT_USER">{{clientsPage?.User}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="clientForm?.controls['client_type'].value == 'organization'" appearance="outline"
        [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
        <mat-label>{{clientsPage?.EntityStatus || 'Entity Status'}}</mat-label>
        <mat-select formControlName="entity_status" floatLabel="always">
          <mat-option value="trial">{{clientsPage?.trial || 'trial'}}</mat-option>
          <mat-option value="paid">{{clientsPage?.paid || 'paid'}}</mat-option>
          <mat-option *ngIf="isEditClient" value="expired">{{clientsPage?.Expired || 'Expired'}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="manualClientForm?.controls['entity_status'].value == 'trial'" appearance="outline"
        [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
        <mat-label>{{clientsPage?.TrialLimit || 'Trial Limit'}}</mat-label>
        <mat-select formControlName="trialLimit" floatLabel="always">
          <mat-option *ngFor="let i of DaysArray; index as j" [value]="i">
            {{ i }} {{j === 0 ? clientsPage?.Day : clientsPage?.Days}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-none justify-content-start align-items-center gap20 my-30">
      <button pButton type="button" class="bgBtn paddingBtn" (click)="fileInput.click()"
        [label]="clientsPage?.Chooselogo"></button>
      <input type='file' (change)="onFileChanged($event)" style="display:none" #fileInput>
    </div>
    <div class="d-flex justify-content-start align-items-center gap20 mt-5">
      <button (click)="OnSave()" class="save-btn">
        <i class="far fa-save"></i>
        {{clientsPage?.SaveClient}}
      </button>
      <button (click)="closeFormWindow()" class="cancel-btn">
        <i class="fas fa-times"></i>
        {{clientsPage?.Cancel}}
      </button>
    </div>
  </form>
</div>
<div class="AddUserForm" *ngIf="AddWSO">
  <form [formGroup]="WSOForm">
    <div class="d-flex flex-wrap gap-3">
      <!-- <input type="text" formControlName="name" class="inputtext sameWidth mb-2 col-xl-2 col-lg-4 col-md-6 col-12" [placeholder]="clientsPage?.Name"> -->
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
        <mat-label>{{clientsPage?.Name}}</mat-label>
        <input formControlName="name" type="text" matInput>
      </mat-form-field>

      <!-- <input type="email" formControlName="email" class="inputtext sameWidth mb-2 col-xl-2 col-lg-4 col-md-6 col-12" [placeholder]="clientsPage?.AddEmail"> -->
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
        class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
        <mat-label>{{clientsPage?.AddEmail}}</mat-label>
        <input formControlName="email" type="text" matInput>
      </mat-form-field>

      <ng-container *ngIf="clientForm.controls['client_type']?.value != 'user'">
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline"
          class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
          <mat-label>{{clientsPage?.APIPackage}}</mat-label>
          <mat-select msInfiniteScroll (infiniteScroll)="infiniteAPIList()" formControlName="package_id">
            <input matInput [placeholder]="clientsPage?.Search" (keyup)="onTypeSearchAPI($event);" class="packageinputfilter">
            <mat-option *ngIf="isLoadingAPIList">
              <div class="d-flex justify-content-center">
                <i class="fa fa-spinner spinner--icon-select"></i>
              </div>
            </mat-option>
            <mat-option [value]="package?.id" *ngFor="let package of listWSOPackages?.data">{{ package?.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>
    <div class="d-flex justify-content-start align-items-center gap20 mt-5">
      <button (click)="onSubmitWSOUser()" class="save-btn">
        <i class="far fa-save"></i>
        {{clientsPage?.SaveClient}}
      </button>
      <button (click)="closeWSOFormWindow()" class="cancel-btn">
        <i class="fas fa-times"></i>
        {{clientsPage?.Cancel}}
      </button>
    </div>
  </form>
</div>

<div ngbNav #nav="ngbNav" [(activeId)]="activeNav" [destroyOnHide]="false">
  <div [ngbNavItem]="navItems[0]">
    <ng-template ngbNavContent>
      <div class="AddUserTable" *ngxPermissionsOnly="['organization_list_users']">
        <!-- <ul class="nav nav-tabs p-3" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active tab-button-text" id="single-user" data-bs-toggle="tab" data-bs-target="#singleuser" type="button" role="tab" aria-controls="singleuser" aria-selected="true">Single Users List</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link tab-button-text" id="bulk-user" data-bs-toggle="tab" data-bs-target="#bulkuser" type="button" role="tab" aria-controls="bulkuser" aria-selected="false">Bulk Users List</button>
          </li>
        </ul> -->
        <!-- <div class="tab-content" id="myTabContent"> -->
        <!-- <div class="tab-pane fade show active" id="singleuser" role="tabpanel" aria-labelledby="single-user"> -->
        <div class="d-flex flex-wrap justify-content-between align-items-center" style="padding: 1.2rem 1.188rem;">
          <div class="col-xl-2 col-lg-12 col-12">
            <div class="d-flex gap-4">
              <h3 class="switching-list-btn active" (click)="onNavChange('Clients')">{{clientsPage?.AddedClients}}</h3>
              <h3 class="switching-list-btn inactive" (click)="onNavChange('APIClients')">{{clientsPage?.AddedWSOUsers || 'Users'}}</h3>
            </div>
          </div>
          <div class="d-flex flex-wrap justify-content-end col-xl-9 col-lg-12 col-12 client-table-gap">
            <div class="search-locations d-flex col-xl-3 col-lg-12 col-12 px-1">
              <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="w-100 client-type-select">
                <mat-label>{{clientsPage?.ClientType}}</mat-label>
                <mat-select (valueChange)="onTypeChange($event)" [placeholder]="clientsPage?.SelectType" floatLabel="always">
                  <mat-option>{{clientsPage?.None}}</mat-option>
                  <mat-option value="government">{{clientsPage?.Government}}</mat-option>
                  <mat-option value="organization">{{clientsPage?.Organization}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="search-locations d-flex col-xl-3 col-lg-12 col-12 px-1">
              <!-- <input class="inputclass w-100" type="text" [placeholder]="clientsPage?.SearchByOrganization" (keyup)="onTypeOrg($event)"> -->
              <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="w-100 client-type-select">
                <mat-label>{{clientsPage?.SearchByOrganization}}</mat-label>
                <mat-select msInfiniteScroll (infiniteScroll)="infiniteOrganizationUsersForDropdown()" floatLabel="always"
                  (valueChange)="onOrganizationChange($event)">
                  <input matInput [placeholder]="clientsPage?.Search" [formControl]="entityInputSearchControl" class="packageinputfilter">
                  <mat-option *ngIf="!isLoadingOrganization">{{clientsPage?.None}}</mat-option>
                  <mat-option *ngIf="isLoadingOrganization">
                    <div class="d-flex justify-content-center">
                      <i class="fa fa-spinner spinner--icon-select"></i>
                    </div>
                  </mat-option>
                  <mat-option [value]="organization?.id" *ngFor="let organization of organizationUsers?.data">
                    {{language === 'en' ? organization?.nameEn : organization?.nameAr}}</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
            <div class="search-locations d-flex col-xl-3 col-lg-12 col-12 px-1">
              <!-- <input class="inputclass w-100" type="text" [placeholder]="clientsPage?.SearchByPackage" (keyup)="onTypePckg($event)"> -->
              <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="w-100 client-type-select">
                <mat-label>{{clientsPage?.Packages}}</mat-label>
                <mat-select msInfiniteScroll (infiniteScroll)="infinitePackagesList(true)" floatLabel="always"
                (valueChange)="onPackageChange($event)">
                  <input matInput [placeholder]="clientsPage?.Search" [formControl]="packagesInputSearchControl" class="packageinputfilter">
                  <mat-option *ngIf="!isLoadingPackages">{{clientsPage?.None}}</mat-option>
                  <mat-option *ngIf="isLoadingPackages">
                    <div class="d-flex justify-content-center">
                      <i class="fa fa-spinner spinner--icon-select"></i>
                    </div>
                  </mat-option>
                  <mat-option [value]="package?.id" *ngFor="let package of listPackagesByFilter?.data">{{language === 'en' ?
                    package?.name: package?.name_ar}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="search-locations d-flex col-xl-3 col-lg-12 col-12 px-1">
              <!-- <input class="inputclass w-100" type="text" [placeholder]="clientsPage?.SearchByNameOrEmail" (keyup)="onTypeEmail($event)"> -->
              <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="client-type-select w-100" appearance="outline">
                <mat-label>{{clientsPage?.SearchByNameOrEmail}}</mat-label>
                <input type="text" matInput (keyup)="onTypeEmail($event)">
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center col-xl-1 col-lg-12 col-12" style="width: max-content;">
            <div class="copy-excel-print">
              <button class="thisone w-100" [ngbTooltip]="clientsPage?.excel" placement="top"
                tooltipClass="custom-tooltip" (click)="generateExcel()">
                <img src="../../../assets/excel-icon.svg">
              </button>
            </div>
          </div>
        </div>
        <div class="client-table mb-30">
          <table class="customTable">
            <thead>
              <tr>
                <th>{{usersPage?.SNo}}</th>
                <th>{{usersPage?.Username}}</th>
                <th>{{usersPage?.Organization}}</th>
                <th>{{usersPage?.EmailAddress}}</th>
                <th>{{clientsPage?.Type}}</th>
                <th>{{clientsPage?.Role}}</th>
                <!-- <th>{{clientsPage?.CreatedBy}}</th> -->
                <!-- <th>{{clientsPage?.CreatedAt}}</th> -->
                <!-- <th class="cursor-pointer" (click)="getClientsList('sortByCreated')">
                  <div class="d-flex align-items-center gap-2">
                    {{clientsPage?.CreatedAt}}
                    <div class="d-flex flex-column justify-content-center">
                      <i [ngClass]="ncmClientModelAPI.sortByCreated === 'asc' ? 'sort-active' : ''"
                        class="fas fa-sort-up sort-icon"></i>
                      <i [ngClass]="ncmClientModelAPI.sortByCreated === 'desc' ? 'sort-active' : ''"
                        class="fas fa-sort-down sort-icon"></i>
                    </div>
                  </div>
                </th> -->
                <th>{{clientsPage?.Packages}}</th>
                <th>{{clientsPage?.PackageExpiry}}</th>
                <!-- <th>{{usersPage?.Status}}</th> -->
                <th class="cursor-pointer" (click)="getClientsList('sortByStatus')">
                  <div class="d-flex align-items-center gap-2">
                    {{usersPage?.Status}}
                    <div class="d-flex flex-column justify-content-center">
                      <i [ngClass]="ncmClientModelAPI.sortByStatus === 'asc' ? 'sort-active' : ''"
                        class="fas fa-sort-up sort-icon"></i>
                      <i [ngClass]="ncmClientModelAPI.sortByStatus === 'desc' ? 'sort-active' : ''"
                        class="fas fa-sort-down sort-icon"></i>
                    </div>
                  </div>
                </th>
                <th class="text-center">{{usersPage?.Actions}}</th>
              </tr>
            </thead>
            <tbody *ngIf="!isLoadingListAPI">
              <ng-container *ngFor="let user of users?.data; index as i">
                <tr>
                  <td>{{((users?.paginationVariables?.current - 1) * pageSize) + i + 1}}</td>
                  <td>{{user?.name}}</td>
                  <td>{{language === 'en' ? user?.company_name_en : user?.company_name_ar}}</td>
                  <td>{{user?.email}}</td>
                  <td>{{(language == 'en' ? user?.client_type.name_en : user?.client_type.name_ar) || '-'}}</td>
                  <td>{{( language == 'en' ? user?.role.name_en : user?.role.name_ar ) || '-'}}</td>
                  <!-- <td>{{user?.createdBy}}</td> -->
                  <!-- <td>{{user?.createdAt | date : 'MMM dd, YYYY'}}</td> -->
                  <td>{{user?.packageData[0]?.name || '-'}}</td>
                  <td>{{user?.packageData[0]?.package_expiry | date : 'MMM dd, YYYY'}}</td>
                  <td>{{ language == 'en' ? user?.status.name_en : user?.status.name_ar }}</td>
                  <td>
                    <div class="d-flex align-items-center gap-4 no-wrap">
                      <div class="d-flex align-items-center gap-3 no-wrap" *ngxPermissionsOnly="['organization_list_users']"
                      data-bs-toggle="modal" data-bs-target="#viewModal" (click)="onViewDetail(user)">
                        <button [ngbTooltip]="usersPage?.ViewDetails" tooltipClass="custom-tooltip">
                          <img src="../../../../assets/alerteditor/view-alert-icon.png">
                        </button>
                      </div>
                      <div class="d-flex align-items-center gap-3 no-wrap" *ngxPermissionsOnly="['edit_user']"
                        (click)="setEditUserForm(user, (user?.status.key === 'Active' ? true : false), false)">
                        <button [ngbTooltip]="usersPage?.EditUser" tooltipClass="custom-tooltip">
                          <img src="../../../../assets/alerteditor/edit-icon-small.png">
                        </button>
                      </div>
                      <div class="d-flex align-items-center gap-3 no-wrap" *ngxPermissionsOnly="['resend_invite']"
                        (click)="showresend(user?.id, false)"
                        [ngClass]="user.status.name_en.includes('Active') || user.status.name_en.includes('Suspended') ? 'disabled': ''">
                        <button class="d-flex gap-2" [ngbTooltip]="usersPage?.ResendInvite" tooltipClass="custom-tooltip">
                          <img src="../../../../assets/inviteIcon.svg">
                        </button>
                      </div>
                      <div class="d-flex align-items-center gap-3 no-wrap" (click)="show(user?.id, false)" *ngxPermissionsOnly="['delete_user']">
                        <button [ngbTooltip]="usersPage?.Delete" tooltipClass="custom-tooltip">
                          <img src="../../../../assets/alerteditor/delete-icon.png">
                        </button>
                      </div>
                      <div [class.disabled]="user?.client_type?.key === 'government'" class="d-flex align-items-center gap-3 no-wrap" (click)="showUpdateEntityModal(user?.organizationId, user?.entity_status)">
                        <button [ngbTooltip]="clientsPage?.UpdateEntityStatus || 'Update Entity Status'" tooltipClass="custom-tooltip" *ngxPermissionsOnly="['edit_user']">
                          <i class="fas fa-user-cog" style="color: #49586c;"></i>
                        </button>
                      </div>
                      <div [class.disabled]="user?.role?.key == 'CLIENT_USER'" (click)="showUpdatePackageModal(user)" class="d-flex align-items-center gap-3 no-wrap">
                        <button [ngbTooltip]="clientsPage?.UpdatePackage" tooltipClass="custom-tooltip">
                          <img src="../../../assets/update-package.svg">
                        </button>
                      </div>
                      <div [class.disabled]="user?.status?.key == 'Active'"  class="d-flex align-items-center gap-3 no-wrap"
                        (click)="showsuspended(user?.id)">
                        <button [ngbTooltip]="clientsPage?.SuspendPackage" tooltipClass="custom-tooltip" *ngxPermissionsOnly="['suspend_package']">
                          <img src="../../../assets/unsubscribe.svg">
                        </button>
                      </div>
                      <div [class.disabled]="user?.status?.key == 'Suspended'" class="d-flex align-items-center gap-3 no-wrap"
                        (click)="showresume(user?.id)">
                        <button [ngbTooltip]="clientsPage?.ResumePackage" tooltipClass="custom-tooltip" *ngxPermissionsOnly="['resume_package']">
                          <img src="../../../assets/subscribe.svg">
                        </button>
                      </div>
                      <div class="d-flex align-items-center gap-3 no-wrap" (click)="showRenewModal(user?.id)">
                        <button [ngbTooltip]="clientsPage?.RenewPackage" tooltipClass="custom-tooltip" *ngxPermissionsOnly="['renew_package']">
                          <img src="../../../assets/renew-package.svg">
                        </button>
                      </div>
                      <!-- <div *ngIf="user?.status?.key == 'Pending'" style="visibility: hidden;" class="d-flex align-items-center gap-3 no-wrap"
                        (click)="showresume(user?.id)">
                        <button [ngbTooltip]="clientsPage?.ResumePackage" tooltipClass="custom-tooltip" *ngxPermissionsOnly="['resume_package']">
                          <img src="../../../assets/subscribe.svg">
                        </button>
                      </div> -->
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center no-data" *ngIf="isLoadingListAPI; else noRecordsTemplate">
          <i class="fa fa-spinner spinner--icon"></i>
        </div>
        <ng-template #noRecordsTemplate>
          <ng-container *ngIf="users?.data?.length === 0 || !users?.data">
            <div class="d-flex justify-content-center no-data">
              {{customNotificationPage?.NoRecordsFound}}
            </div>
          </ng-container>
        </ng-template>
        <!-- </div> -->
        <!-- <div class="tab-pane fade" id="bulkuser" role="tabpanel" aria-labelledby="bulk-user">
            <div class="d-flex flex-wrap justify-content-end align-items-center" style="padding: 1.2rem 1.188rem;">
              <div class="addLocHeading col-xl-3 col-lg-12 col-12">
                <h3>Clients with Bulk Users</h3>
              </div>
              <div class="d-flex flex-wrap justify-content-end col-xl-9 col-lg-12 col-12">
                <div class="search-locations d-flex col-xl-3 col-lg-12 col-12 px-1">
                  <input class="inputclass w-100" type="text" [placeholder]="clientsPage?.SearchByNameOrEmail" (keyup)="onTypeEmail($event)">
                </div>
              </div>
            </div>
            <div class="client-table mb-30">
              <table class="customTable" *ngxPermissionsOnly="['list_organization']">
                <thead>
                  <tr>
                    <th>{{usersPage?.SNo}}</th>
                    <th>{{usersPage?.Organization}}</th>
                    <th>{{clientsPage?.Packages}}</th>
                    <th>{{clientsPage?.PackageExpiry}}</th>
                    <th class="">{{usersPage?.Actions}}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let user of users?.data; index as i">
                    <tr>
                      <td>{{ (page - 1) * pageSize + i + 1 }}</td>
                      <td>{{language === 'en' ? user?.company_name_en : user?.company_name_ar}}</td>
                      <td>{{user?.packageData[0]?.name || '-'}}</td>
                      <td>{{user?.packageData[0]?.package_expiry | date : 'MMM dd, YYYY'}}</td>
                      <td>
                        <div class="d-flex align-items-center gap-4 no-wrap">
                          <div class="d-flex align-items-center gap-3 no-wrap" *ngxPermissionsOnly="['edit_user']" (click)="setEditUserForm(user)">
                            <button [ngbTooltip]="usersPage?.EditUser" tooltipClass="custom-tooltip">
                              <img src="../../../assets/addbulkuser-icon.png">
                            </button>
                          </div>
                          <div class="d-flex align-items-center gap-3 no-wrap"
                            *ngxPermissionsOnly="['delete_ncm_user']" data-bs-toggle="modal" data-bs-target="#exampleModal" style="cursor: pointer;">
                            <button [ngbTooltip]="usersPage?.Delete" tooltipClass="custom-tooltip">
                              <img src="../../../assets/viewbulkuser-icon.png">
                            </button>
                          </div>
                          <div class="d-flex align-items-center gap-3 no-wrap" *ngxPermissionsOnly="['edit_user']" (click)="setEditUserForm(user)">
                            <button [ngbTooltip]="usersPage?.EditUser" tooltipClass="custom-tooltip">
                              <img src="../../../assets/alerteditor/edit-icon-small.png">
                            </button>
                          </div>
                          <div class="d-flex align-items-center gap-3 no-wrap" *ngxPermissionsOnly="['resend_invite']"
                            (click)="resendInviteUser(user?.id)"
                            [ngClass]="user.status.includes('Active' || 'Suspended') ? 'disabled': ''">
                            <button class="d-flex gap-2" [ngbTooltip]="usersPage?.ResendInvite" tooltipClass="custom-tooltip">
                              <img src="../../../assets/resendinvitebulkuser-icon.png">
                            </button>
                          </div>
                          <div *ngIf="user?.status == 'Active'" class="d-flex align-items-center gap-3 no-wrap" (click)="onSuspendPackage(user?.id)">
                            <button [ngbTooltip]="clientsPage?.SuspendPackage" tooltipClass="custom-tooltip" *ngxPermissionsOnly="['suspend_package']">
                              <img src="../../../../assets/alerteditor/delete-icon.png">
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div> -->
        <!-- </div> -->
        <div *ngIf="users?.paginationVariables?.pageCount > 1 && !isLoadingListAPI"
          class="col-12 d-flex justify-content-center pagination dir-left mb-30">
          <ngb-pagination (pageChange)="onPageChange($event)" [maxSize]="3" [rotate]="true" [pageSize]="pageSize"
            [(page)]="page" [collectionSize]="users?.paginationVariables?.totalCount"></ngb-pagination>
        </div>
      </div>
    </ng-template>
  </div>
  <div [ngbNavItem]="navItems[1]" [destroyOnHide]="true">
    <ng-template ngbNavContent>
      <div class="AddUserTable" *ngxPermissionsOnly="['organization_list_users']">
        <!-- <ul class="nav nav-tabs p-3" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active tab-button-text" id="single-user" data-bs-toggle="tab" data-bs-target="#singleuser" type="button" role="tab" aria-controls="singleuser" aria-selected="true">Single Users List</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link tab-button-text" id="bulk-user" data-bs-toggle="tab" data-bs-target="#bulkuser" type="button" role="tab" aria-controls="bulkuser" aria-selected="false">Bulk Users List</button>
          </li>
        </ul> -->
        <!-- <div class="tab-content" id="myTabContent"> -->
        <!-- <div class="tab-pane fade show active" id="singleuser" role="tabpanel" aria-labelledby="single-user"> -->
        <div class="d-flex flex-wrap justify-content-between align-items-center" style="padding: 1.2rem 1.188rem;">
          <div class="col-xl-2 col-lg-12 col-12">
            <div class="d-flex gap-4">
              <h3 class="switching-list-btn inactive" (click)="onNavChange('Clients')">{{clientsPage?.AddedClients}}</h3>
              <h3 class="switching-list-btn active" (click)="onNavChange('APIClients')">{{clientsPage?.AddedWSOUsers || 'Users'}}</h3>
            </div>
          </div>
          <div class="d-flex flex-wrap justify-content-end col-xl-9 col-lg-12 col-12 client-table-gap">
            <div class="search-locations d-flex col-xl-3 col-lg-12 col-12 px-1">
              <!-- <input class="inputclass w-100" type="text" [placeholder]="clientsPage?.SearchByPackage" (keyup)="onTypePckg($event)"> -->
              <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="w-100 client-type-select">
                <mat-label>{{clientsPage?.APIPackage || 'API Package'}}</mat-label>
                <mat-select msInfiniteScroll (infiniteScroll)="infiniteAPIList(true)" floatLabel="always" (valueChange)="onWSOPackageChange($event)">
                  <input matInput [placeholder]="clientsPage?.Search" (keyup)="onTypeSearchAPIFilter($event); isFilter = true" class="packageinputfilter">
                  <mat-option *ngIf="!isLoadingAPIFilterList">{{clientsPage?.None}}</mat-option>
                  <mat-option *ngIf="isLoadingAPIFilterList">
                    <div class="d-flex justify-content-center">
                      <i class="fa fa-spinner spinner--icon-select"></i>
                    </div>
                  </mat-option>
                  <mat-option [value]="package?.id" *ngFor="let package of listWSOPackagesByFilter?.data">{{package?.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="search-locations d-flex col-xl-3 col-lg-12 col-12 px-1">
              <!-- <input class="inputclass w-100" type="text" [placeholder]="clientsPage?.SearchByNameOrEmail" (keyup)="onTypeEmail($event)"> -->
              <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="client-type-select w-100" appearance="outline">
                <mat-label>{{clientsPage?.SearchByNameOrEmail}}</mat-label>
                <input type="text" matInput (keyup)="onTypeEmailAPI($event)">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="client-table mb-30">
          <table class="customTable">
            <thead>
              <tr>
                <th>{{usersPage?.SNo}}</th>
                <th>{{usersPage?.Name}}</th>
                <th>{{usersPage?.EmailAddress}}</th>
                <th>{{clientsPage?.CreatedBy}}</th>
                <th>{{clientsPage?.CreatedAt}}</th> <!-- CreatedAt -->
                <!-- <th class="cursor-pointer" (click)="getClientsList('sortByCreated')">
                  <div class="d-flex align-items-center gap-2">
                    {{clientsPage?.CreatedAt}}
                    <div class="d-flex flex-column justify-content-center">
                      <i [ngClass]="ncmClientModelAPI.sortByCreated === 'asc' ? 'sort-active' : ''"
                        class="fas fa-sort-up sort-icon"></i>
                      <i [ngClass]="ncmClientModelAPI.sortByCreated === 'desc' ? 'sort-active' : ''"
                        class="fas fa-sort-down sort-icon"></i>
                    </div>
                  </div>
                </th> -->
                <th>{{clientsPage?.Packages}}</th>
                <th>{{usersPage?.Status}}</th> <!-- Status -->
                <!-- <th class="cursor-pointer" (click)="getClientsList('sortByStatus')">
                  <div class="d-flex align-items-center gap-2">
                    {{usersPage?.Status}}
                    <div class="d-flex flex-column justify-content-center">
                      <i [ngClass]="ncmClientModelAPI.sortByStatus === 'asc' ? 'sort-active' : ''"
                        class="fas fa-sort-up sort-icon"></i>
                      <i [ngClass]="ncmClientModelAPI.sortByStatus === 'desc' ? 'sort-active' : ''"
                        class="fas fa-sort-down sort-icon"></i>
                    </div>
                  </div>
                </th> -->
                <th class="text-center">{{usersPage?.Actions}}</th>
              </tr>
            </thead>
            <tbody *ngIf="!isLoadingListAPI">
              <ng-container *ngFor="let user of listWSOUsers?.data; index as i">
                <tr>
                  <td>{{((listWSOUsers?.paginationVariables?.current - 1) * pageSize) + i + 1}}</td>
                  <td>{{user?.user_name}}</td>
                  <td>{{user?.user_email}}</td>
                  <td>{{user?.createdBy || '-'}}</td>
                  <td>{{ (user?.createdOn | date : 'MMM dd, YYYY') || '-'}}</td>
                  <td>{{user?.package_name || '-'}}</td>
                  <td>{{ language == 'en' ? user?.status.name_en : user?.status.name_ar }}</td>
                  <td>
                    <div class="d-flex align-items-center gap-4 no-wrap justify-content-center">
                      <div class="d-flex align-items-center gap-3 no-wrap" *ngxPermissionsOnly="['edit_user']"
                        (click)="setWSOeditUserForm(user)">
                        <button [ngbTooltip]="usersPage?.EditUser" tooltipClass="custom-tooltip">
                          <img src="../../../../assets/alerteditor/edit-icon-small.png">
                        </button>
                      </div>
                      <div class="d-flex align-items-center gap-3 no-wrap" *ngxPermissionsOnly="['resend_invite']" (click)="showresend(user?.user_id, true)"
                        [ngClass]="user.subscription_status ? '': 'disabled'">
                        <button class="d-flex gap-2" [ngbTooltip]="usersPage?.ResendInvite" tooltipClass="custom-tooltip">
                          <img src="../../../../assets/inviteIcon.svg">
                        </button>
                      </div>
                      <div class="d-flex align-items-center gap-3 no-wrap" *ngxPermissionsOnly="['delete_user']" (click)="show(user?.user_id, true)">
                        <button [ngbTooltip]="usersPage?.Delete" tooltipClass="custom-tooltip">
                          <img src="../../../../assets/alerteditor/delete-icon.png">
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center no-data" *ngIf="isLoadingListAPI; else noUserRecordsTemplate">
          <i class="fa fa-spinner spinner--icon"></i>
        </div>
        <ng-template #noUserRecordsTemplate>
          <ng-container *ngIf="listWSOUsers?.data?.length === 0 || !listWSOUsers?.data">
            <div class="d-flex justify-content-center no-data">
              {{customNotificationPage?.NoRecordsFound}}
            </div>
          </ng-container>
        </ng-template>
        <div *ngIf="listWSOUsers?.paginationVariables?.pageCount > 1 && !isLoadingListAPI"
          class="col-12 d-flex justify-content-center pagination dir-left mb-30">
          <ngb-pagination (pageChange)="onWSOPageChange($event)" [maxSize]="3" [rotate]="true" [pageSize]="pageSize"
            [(page)]="pageWSO" [collectionSize]="listWSOUsers?.paginationVariables?.totalCount"></ngb-pagination>
        </div>
      </div>
    </ng-template>
  </div>
  <div [ngbNavOutlet]="nav"></div>
</div>

<div *ngIf="idEntityPackageUpdate" class="deletemodalbg">
  <div class="deletemodal">
    <i class="far fa-times-circle dltcross" (click)="closeUpdatePackageModal()"></i>
    <p style="font: normal normal 400 1.5vw Roboto;color: #0051AC;text-align: center;margin-bottom: 1vw;">
      {{clientsPage?.UpdatePackage}}
    </p>
    <!-- <p style="font: normal normal 400 1vw Roboto;text-align: center;color: #49586C;margin-bottom: 2vw;">
      {{clientsPage?.DoYouReallyWant}}</p> -->
    <div class="input-group w-50">
      <ng-container>
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="sameHeight mb-2">
          <mat-label>{{clientsPage?.Packages}}</mat-label>
          <mat-select msInfiniteScroll (infiniteScroll)="infinitePackagesList()" [(ngModel)]="entityPackageId">
            <input matInput [placeholder]="clientsPage?.Search" (keyup)="searchPckg($event)" [formControl]="pkgSearchControl" class="packageinputfilter">
            <mat-option *ngIf="isLoadingPackagesList">
              <div class="d-flex justify-content-center">
                <i class="fa fa-spinner spinner--icon-select"></i>
              </div>
            </mat-option>
            <mat-option [value]="package?.id" *ngFor="let package of listPackages?.data">
              {{language === 'en' ? package?.name : package?.name_ar}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>
    <div class="polyBtnDiv d-flex align-items-center justify-content-center gap-5 btn-div pt-3">
      <button class="save-btn" (click)="updatePackageForEntity(userIdForPackageUpdate, organizationIdForPackageUpdate);closeUpdatePackageModal()">
        <i class="far fa-save"></i>
        {{clientsPage?.Save}}
      </button>
      <button class="cancel-btn" (click)="closeUpdatePackageModal()">
        <i class="fas fa-times"></i>
        {{clientsPage?.Cancel}}
      </button>
    </div>
  </div>
</div>
<div *ngIf="isRenewVisible" class="deletemodalbg">
  <div class="deletemodal">
    <i class="far fa-times-circle dltcross" (click)="closeRenewModal()"></i>
    <p style="font: normal normal 400 1.5vw Roboto;color: #0051AC;text-align: center;margin-bottom: 1vw;">
      {{clientsPage?.RenewalOfYourPackage}}
    </p>
    <!-- <p style="font: normal normal 400 1vw Roboto;text-align: center;color: #49586C;margin-bottom: 2vw;">
      {{clientsPage?.DoYouReallyWant}}</p> -->
    <div class="input-group w-50">
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="w-100 client-type-select">
        <mat-label>{{clientsPage?.ClientType}}</mat-label>
        <mat-select [(ngModel)]="renewMonthNumber" [placeholder]="clientsPage?.PleaseSelectADuration" floatLabel="always">
          <mat-option *ngFor="let i of optionsArray; index as j" [value]="i">
            {{ i }} {{j === 0 ? clientsPage?.Day : clientsPage?.Days}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="polyBtnDiv d-flex align-items-center justify-content-center gap-5 btn-div pt-3">
      <button class="save-btn" (click)="onRenewPackage(renewPackageId, renewMonthNumber);closeRenewModal()">
        <i class="far fa-save"></i>
        {{clientsPage?.Save}}
      </button>
      <button class="cancel-btn" (click)="closeRenewModal()">
        <i class="fas fa-times"></i>
        {{clientsPage?.Cancel}}
      </button>
    </div>
  </div>
</div>
<div *ngIf="updateEntity" class="deletemodalbg">
  <div class="deletemodal">
    <i class="far fa-times-circle dltcross" (click)="closeUpdateEntityModal()"></i>
    <p style="font: normal normal 400 1.5vw Roboto;color: #0051AC;text-align: center;margin-bottom: 1vw;">
      {{clientsPage?.UpdateEntityStatus || 'Update Entity Status'}}
    </p>
    <!-- <p style="font: normal normal 400 1vw Roboto;text-align: center;color: #49586C;margin-bottom: 2vw;">
      {{clientsPage?.DoYouReallyWant}}</p> -->
    <div class="input-group w-50">
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="w-100 client-type-select">
        <mat-label>{{clientsPage?.EntityStatus || 'Entity Status'}}</mat-label>
        <mat-select [(ngModel)]="updateEntityStatus" floatLabel="always">
          <mat-option value="trial">{{clientsPage?.trial || 'Trial'}}</mat-option>
          <mat-option value="paid">{{clientsPage?.paid || 'Paid'}}</mat-option>
          <mat-option value="expired">{{clientsPage?.Expired || 'Expired'}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="updateEntityStatus == 'trial'" appearance="outline" [dir]="language === 'en' ? 'ltr' : 'rtl'" class="w-100 client-type-select mt-3">
        <mat-label>{{clientsPage?.TrialLimit || 'Trial Limit'}}</mat-label>
        <mat-select [(ngModel)]="trialLimit" floatLabel="always">
          <mat-option *ngFor="let i of DaysArray; index as j" [value]="i">
            {{ i }} {{j === 0 ? clientsPage?.Day : clientsPage?.Days}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="polyBtnDiv d-flex align-items-center justify-content-center gap-5 btn-div pt-3">
      <button class="save-btn" (click)="onUpdateEntity(updateEntityId, updateEntityStatus, trialLimit)">
        <i class="far fa-save"></i>
        {{clientsPage?.Save}}
      </button>
      <button class="cancel-btn" (click)="closeUpdateEntityModal()">
        <i class="fas fa-times"></i>
        {{clientsPage?.Cancel}}
      </button>
    </div>
  </div>
</div>
<div *ngIf="isResendInvite" class="deletemodalbg">
  <div class="deletemodal">
    <i class="far fa-times-circle dltcross" (click)="cancelresend()"></i>
    <p style="font: normal normal 400 1.5vw Roboto;color: #0051AC;text-align: center;margin-bottom: 2vw;">
      {{clientsPage?.AreYouSure}}
    </p>
    <!-- <p style="font: normal normal 400 1vw Roboto;text-align: center;color: #49586C;margin-bottom: 2vw;">
      {{clientsPage?.DoYouReallyWant}}</p> -->
    <div class="polyBtnDiv d-flex align-items-center justify-content-center gap-5 btn-div">
      <button class="save-btn" (click)="resendInviteUser(resendInviteId); cancelresend()">
        <i class="fas fa-trash-alt"></i>
        {{clientsPage?.Yes}}
      </button>
      <button class="cancel-btn" (click)="cancelresend()">
        <i class="fas fa-times"></i>
        {{clientsPage?.No}}
      </button>
    </div>
  </div>
</div>
<div *ngIf="isSuspended" class="deletemodalbg">
  <div class="deletemodal">
    <i class="far fa-times-circle dltcross" (click)="cancelsuspended()"></i>
    <p style="font: normal normal 400 1.5vw Roboto;color: #0051AC;text-align: center;margin-bottom: 2vw;">
      {{clientsPage?.AreYouSure}}
    </p>
    <!-- <p style="font: normal normal 400 1vw Roboto;text-align: center;color: #49586C;margin-bottom: 2vw;">
      {{clientsPage?.DoYouReallyWant}}</p> -->
    <div class="polyBtnDiv d-flex align-items-center justify-content-center gap-5 btn-div">
      <button class="save-btn" (click)="onSuspendPackage(suspendedId);cancelsuspended()">
        <i class="fas fa-trash-alt"></i>
        {{clientsPage?.Yes}}
      </button>
      <button class="cancel-btn" (click)="cancelsuspended()">
        <i class="fas fa-times"></i>
        {{clientsPage?.No}}
      </button>
    </div>
  </div>
</div>
<div *ngIf="isResume" class="deletemodalbg">
  <div class="deletemodal">
    <i class="far fa-times-circle dltcross" (click)="cancelresume()"></i>
    <p style="font: normal normal 400 1.5vw Roboto;color: #0051AC;text-align: center;margin-bottom: 2vw;">
      {{clientsPage?.AreYouSure}}
    </p>
    <!-- <p style="font: normal normal 400 1vw Roboto;text-align: center;color: #49586C;margin-bottom: 2vw;">
      {{clientsPage?.DoYouReallyWant}}</p> -->
    <div class="polyBtnDiv d-flex align-items-center justify-content-center gap-5 btn-div">
      <button class="save-btn" (click)="onResumePackage(resumeId);cancelresume()">
        <i class="fas fa-trash-alt"></i>
        {{clientsPage?.Yes}}
      </button>
      <button class="cancel-btn" (click)="cancelresume()">
        <i class="fas fa-times"></i>
        {{clientsPage?.No}}
      </button>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" style="max-width: 90vw !important;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title w-100 text-center modalheadingtext" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="client-table mb-30">
          <table class="customTable" *ngxPermissionsOnly="['list_organization']">
            <thead>
              <tr>
                <th>{{usersPage?.SNo}}</th>
                <th>{{usersPage?.Username}}</th>
                <th>{{usersPage?.Organization}}</th>
                <th>{{usersPage?.EmailAddress}}</th>
                <th>{{clientsPage?.Type}}</th>
                <th>{{clientsPage?.Role}}</th>
                <th>{{clientsPage?.Packages}}</th>
                <th>{{clientsPage?.PackageExpiry}}</th>
                <th>{{usersPage?.Status}}</th>
                <th class="">{{usersPage?.Actions}}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let user of users?.data; index as i">
                <tr>
                  <td>{{((users?.paginationVariables?.current - 1) * pageSize) + i + 1}}</td>
                  <td>{{user?.name}}</td>
                  <td>{{language === 'en' ? user?.company_name_en : user?.company_name_ar}}</td>
                  <td>{{user?.email}}</td>
                  <td>{{user?.client_type.name_en || '-'}}</td>
                  <td>{{user?.role.name_en || '-'}}</td>
                  <td>{{user?.packageData[0]?.name || '-'}}</td>
                  <td>{{user?.packageData[0]?.package_expiry | date : 'MMM dd, YYYY'}}</td>
                  <td>{{user?.status.name_en}}</td>
                  <td>
                    <div class="d-flex align-items-center gap-4 no-wrap">
                      <div class="d-flex align-items-center gap-3 no-wrap" *ngxPermissionsOnly="['resend_invite']"
                        (click)="resendInviteUser(user?.id)"
                        [ngClass]="user.status.name_en.includes('Active') ? 'disabled': ''">
                        <button class="d-flex gap-2" [ngbTooltip]="usersPage?.ResendInvite"
                          tooltipClass="custom-tooltip">
                          <img src="../../../assets/resendinvitebulkuser-icon.png">
                        </button>
                      </div>
                      <div *ngIf="user?.status?.key == 'Active'" class="d-flex align-items-center gap-3 no-wrap"
                        (click)="onSuspendPackage(user?.id)">
                        <button [ngbTooltip]="clientsPage?.SuspendPackage" tooltipClass="custom-tooltip"
                          *ngxPermissionsOnly="['suspend_package']">
                          <img src="../../../../assets/alerteditor/delete-icon.png">
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <div *ngIf="users?.paginationVariables?.pageCount > 1"
          class="col-12 d-flex justify-content-center pagination dir-left mb-30">
          <ngb-pagination (pageChange)="onPageChange($event)" [maxSize]="3" [rotate]="true" [pageSize]="pageSize"
            [(page)]="page" [collectionSize]="users?.paginationVariables?.totalCount"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="viewModal" tabindex="-1" aria-labelledby="viewModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" style="min-width: 75vw;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title viewmaintitle" id="exampleModalLabel">{{ clientsPage?.ClientDetails }}</h5>
        <button type="button" class="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="client-table mb-30">
          <table class="customTable">
            <thead>
              <tr>
                <th>{{usersPage?.Username}}</th>
                <th>{{usersPage?.Organization}}</th>
                <th>{{usersPage?.EmailAddress}}</th>
                <th>{{clientsPage?.Phone}}</th>
                <th>{{clientsPage?.Department}}</th>
                <th>{{clientsPage?.Title}}</th>
                <th>{{clientsPage?.NationalId}}</th>
                <th>{{clientsPage?.Type}}</th>
                <th *ngIf="singleUser?.client_type?.key !== 'government'">{{clientsPage?.EntityStatus || 'Entity Status'}}</th>
                <th *ngIf="singleUser?.client_type?.key !== 'government' && singleUser?.entity_status === 'trial' && singleUser?.trialLeftDays > 1">{{clientsPage?.DaysLeft || 'Days Left'}}</th>
                <th>{{clientsPage?.Role}}</th>
                <th>{{clientsPage?.CreatedBy}}</th>
                <th>{{clientsPage?.CreatedAt}}</th>
                <th>{{clientsPage?.Packages}}</th>
                <th>{{clientsPage?.PackageExpiry}}</th>
                <th>{{usersPage?.Status}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{singleUser?.name}}</td>
                <td>{{language === 'en' ? singleUser?.company_name_en : singleUser?.company_name_ar}}</td>
                <td>{{singleUser?.email}}</td>
                <td>{{singleUser?.phone ? singleUser?.phone : '-'}}</td>
                <td>{{singleUser?.department ? singleUser?.department : '-'}}</td>
                <td>{{singleUser?.title ? singleUser?.title : '-'}}</td>
                <td>{{singleUser?.iqamaId ? singleUser?.iqamaId : '-'}}</td>
                <td>{{(language == 'en' ? singleUser?.client_type.name_en : singleUser?.client_type.name_ar) || '-'}}</td>
                <td style="text-transform: capitalize;" *ngIf="singleUser?.client_type.key !== 'government'">{{language == 'en' ? singleUser?.entity_status_en : singleUser?.entity_status_ar}}</td>
                <td *ngIf="singleUser?.client_type?.key !== 'government' && singleUser?.entity_status === 'trial' && singleUser?.trialLeftDays > 1">{{singleUser?.trialLeftDays}} {{singleUser?.trialLeftDays === 0 ? clientsPage?.Day : clientsPage?.Days}}</td>
                <td>{{(language == 'en' ? singleUser?.role.name_en : singleUser?.role.name_ar) || '-'}}</td>
                <td>{{singleUser?.createdBy}}</td>
                <td>{{singleUser?.createdAt | date : 'MMM dd, YYYY'}}</td>
                <td>{{singleUser?.packageData[0]?.name || '-'}}</td>
                <td>{{singleUser?.packageData[0]?.package_expiry | date : 'MMM dd, YYYY'}}</td>
                <td>{{ language == 'en' ? singleUser?.status.name_en : singleUser?.status.name_ar }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="bulkStatus" class="deletemodalbg">
  <div class="deletemodal" style="padding: 15px 20px 30px; align-items: flex-start;">
    <div class="d-flex justify-content-between align-items-center w-100 pb-3">
      <p class="m-0" style="font-size: 1.725em; font-weight: 500; color: #0051AC;">
        {{clientsPage?.BulkUploadStatus}}
      </p>
      <i class="far fa-times-circle dlticon" (click)="closeBulkExcelStatus()"></i>
    </div>
    <div class="client-table">
      <table class="customTable excelStatusTable">
        <thead>
          <tr>
            <th>{{clientsPage?.TotalRecords}}</th>
            <th>{{clientsPage?.SuccessfullyAdded}}</th>
            <th>{{clientsPage?.FailedRecords}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">{{ totalRecords }}</td>
            <td class="text-center">{{ successfullyAdded }}</td>
            <td class="text-center">{{ failedRecords }}</td>
          </tr>
        </tbody>
      </table>
      <p class="my-3" style="font-size: 1.125em; cursor: pointer;"><a (click)="closeBulkExcelStatusModal()" class="text-decoration-underline">{{ clientsPage?.ViewDetails }}</a></p>
    </div>
  </div>
</div>
<div *ngIf="bulkStatusReport" class="deletemodalbg">
  <div class="deletemodal" style="padding: 15px 20px 30px; align-items: flex-start;">
    <div class="d-flex justify-content-between align-items-center w-100 pb-3">
      <p class="m-0" style="font-size: 1.725em; font-weight: 500; color: #0051AC;">
        {{clientsPage?.BulkUploadDetailReport}}
      </p>
      <i class="far fa-times-circle dlticon" (click)="closeBulkStatusReport()"></i>
    </div>
    <div class="client-table">
      <table class="customTable excelStatusTable">
        <thead>
          <tr>
            <th style="padding-inline-start: 10px !important;">{{clientsPage?.SNo}}</th>
            <th>{{usersPage?.Username}}</th>
            <th>{{usersPage?.EmailAddress}}</th>
            <th>{{clientsPage?.Role}}</th>
            <th>{{clientsPage?.Status}}</th>
            <th>{{clientsPage?.ErrorMessage}}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of bulkStatusRecord; index as i">
            <tr>
              <td class="text-center">{{ i + 1 }}</td>
              <td class="text-center">{{ item?.data?.name }}</td>
              <td class="text-center">{{ item?.data?.email }}</td>
              <td class="text-center">{{ item?.data?.role == 'CLIENT_ADMIN' ? clientsPage?.Admin : clientsPage.User }}</td>
              <td class="text-center">{{ item?.success ? clientsPage?.Success : clientsPage?.Failure }}</td>
              <td class="text-center">{{ item?.success !== false ? '-' : item?.message }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div *ngIf="bulkUploadStatusReport" class="deletemodalbg">
  <div class="deletemodal" style="padding: 15px 20px 30px; align-items: flex-start;">
    <div class="d-flex justify-content-between align-items-center w-100 pb-3">
      <p class="m-0" style="font-size: 1.725em; font-weight: 500; color: #0051AC;">
        {{clientsPage?.BulkUploadDetailReport}}
      </p>
      <i class="far fa-times-circle dlticon" (click)="closeBulkStatusValidationReport()"></i>
    </div>
    <div class="client-table">
      <table class="customTable excelStatusTable">
        <thead>
          <tr>
            <th style="padding-inline-start: 10px !important;">{{clientsPage?.SNo}}</th>
            <th>{{usersPage?.Username}}</th>
            <th>{{usersPage?.EmailAddress}}</th>
            <th>{{clientsPage?.Role}}</th>
            <th>{{clientsPage?.Status}}</th>
            <th>{{clientsPage?.ErrorMessage}}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of bulkStatusValidationRecord; index as i">
            <tr>
              <td class="text-center" >{{ i + 1 }}</td>
              <td class="text-center">{{ item?.name }}</td>
              <td class="text-center">{{ item?.email }}</td>
              <td class="text-center">{{ item?.role }}</td>
              <td class="text-center">{{ item?.status ? clientsPage?.Success : clientsPage?.Failure }}</td>
              <td class="text-center">{{ item?.error_message }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
